<template>
  <div class="order-tabs">
    <div class="order-tabs__content">
      <div class="order-tabs__switches">
        <div
          @click="setActive(2)"
          class="order-tabs__switch"
          :class="{ 'order-tabs__switch--active': activeTab === 2 }"
        >
          <IconComponent name="pickup" />
          <span>Доставка</span>
        </div>
        <div
          @click="setActive(1)"
          class="order-tabs__switch"
          :class="{ 'order-tabs__switch--active': activeTab === 1 }"
        >
          <IconComponent name="pickup" />
          <span>Самовывоз</span>
          <span v-show="false">{{ client_delivery_info }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "OrderTabsComponent",
  components: { IconComponent },
  data() {
    return {
      activeTab: 2,
    };
  },
  computed: {
    client_delivery_info() {
      let client_delivery_info = this.$store.state.auth.client_delivery_info;
      if (client_delivery_info && client_delivery_info.type_id) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.activeTab = client_delivery_info.type_id;
      }
      return client_delivery_info;
    },
  },
  methods: {
    setActive(code) {
      this.activeTab = code;
      if (code === 1) {
        this.setPickupType();
      } else {
        this.setDeliveryType();
      }
    },
    setDeliveryType() {},
    setPickupType() {},
  },
};
</script>

<style lang="stylus">
.order-tabs {
  display flex
  justify-content space-between

  &__content {
    width 100%
    display flex
    gap 16px
  }

  &__switches {
    width 100%
    padding 0 2px
    background var(--white)
    border 1px solid var(--border-color-2)
    border-radius 8px
    display flex
    height 46px
    align-items center
    transition var(--transition)
  }

  &__switch {
    width 100%
    display flex
    align-items center
    justify-content center
    gap 4px
    font-size .875em
    line-height 140%
    color var(--gray-800)
    padding 9px 12px
    border-radius 8px
    cursor pointer
    height 40px
    transition var(--transition)

    .icon svg path {
      fill var(--gray-800)
    }

    &:hover {
      border: 1px solid var(--gray-800);
    }

    &--active {
      color: var(--white);
      background: var(--gray-800);
      border: 1px solid var(--gray-800);

      .icon svg path {
        fill var(--white)
      }
    }
  }
}
</style>
