<template>
  <main class="order-page page">
    <div class="container-padding">
      <div class="page__inner">
        <div class="order-page__content">
          <div class="order-page__left">
            <OrderAddressComponent @input="addressForm = $event" />
            <OrderDateComponent @chooseSlot="form.slot_time.value = $event" :error="form.slot_time.error" />
            <!--            <OrderSettingsComponent />-->
            <OrderAddComponent />
            <OrderPaymentComponent
              @chooseCard="form.card_id.value = $event"
              :card="form.card_id.value"
              :error="form.card_id.error"
              ref="cards"
            />
            <OrderContactsComponent @input="contactsForm = $event" ref="contacts" />
          </div>
          <div class="order-page__right">
            <OrderDetailsComponent @submit="orderCreate" />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import OrderDetailsComponent from "./components/OrderDetailsComponent.vue";
import OrderPaymentComponent from "./components/OrderPaymentComponent.vue";
import OrderAddComponent from "./components/OrderAddComponent.vue";
import OrderContactsComponent from "./components/OrderContactsComponent.vue";
import OrderAddressComponent from "./components/OrderAddressComponent.vue";
import OrderDateComponent from "./components/OrderDateComponent.vue";
import OrderSettingsComponent from "./components/OrderSettingsComponent.vue";
import AlertModal from "components/modals/components/AlertModal.vue";
import ORDER_CREATE from "@/graphql/mutations/OrderCreate.graphql";
import { resetErrors } from "../../utils";

export default {
  name: "OrderPage",
  components: {
    OrderSettingsComponent,
    OrderDateComponent,
    OrderAddressComponent,
    OrderContactsComponent,
    OrderAddComponent,
    OrderPaymentComponent,
    OrderDetailsComponent,
  },
  data() {
    return {
      addressForm: {},
      contactsForm: {},
      form: {
        slot_time: {
          value: null,
          error: null,
        },
        card_id: {
          value: null,
          error: null,
        },
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user || {};
    },
    token() {
      return this.$store.state.auth_token;
    },
  },
  methods: {
    resetErrors,
    orderCreate() {
      this.resetErrors("form");
      this.$refs.contacts.resetErrors();
      this.$apollo.provider.clients.purchase
        .mutate({
          mutation: ORDER_CREATE,
          variables: {
            cart: true,
            client_comment: this.addressForm?.client_comment?.value || undefined,
            payment_type_id: 1,
            name: this.user.name || undefined,
            surname: this.user.surname || undefined,
            phone: this.contactsForm.phone?.value || undefined,
            email: this.contactsForm.email?.value || undefined,
            card_id: this.form.card_id.value ? this.form.card_id.value.id : undefined,
            slot_time: this.form.slot_time.value || undefined,
            promo: this.$store.state.activePromoCode || undefined,
            use_points: this.$store.state.order_page.use_points || undefined,
          },
          context: {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          },
        })
        .then(({ data }) => {
          if (data.OrderCreate) {
            this.$store.state.auth.cart = [];
            this.$store.state.activePromoCode = null;
            this.$store.state.order_page.promo_code_sale_value = null;
            this.$store.state.promo_products = [];
            this.$store.dispatch("GET_DELIVERY_INFO", {
              apollo: this.$apollo.provider.clients,
              token: this.token,
            });
            this.showAlertModal({
              title: "Заказ успешно оформлен",
              message: "Вы можете следить за состоянием заказа в личном кабинете",
              closeBtnClass: "btn--gray",
              isConfirm: true,
              confirmBtnTitle: "Перейти",
              modalId: "order_created_modal",
              callback: () => {
                this.$router.push({ name: "profile", params: { link: "orders" } });
              },
            });
          }
        })
        .catch(({ graphQLErrors }) => {
          this.loading = false;
          this.parseGqlErrors(graphQLErrors);
        });
    },
    showAlertModal(options, callback) {
      this.$store.state._modals.push({
        component: AlertModal,
        options: options,
        callback,
      });
    },
    parseGqlErrors(graphQLErrors) {
      this.$refs.contacts.parseGqlErrors(graphQLErrors);
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          let validationKeys = Object.keys(err.extensions.validation);
          if (validationKeys.includes("name") || validationKeys.includes("surname")) {
            this.showAlertModal({
              title: "Ошибка",
              message: "Для оформления заказа необходимо ввести имя и фамилию в личном кабинете",
              isConfirm: true,
              closeBtnTitle: "Отмена",
              confirmBtnTitle: "Изменить",
              closeBtnClass: "btn--gray",
              callback: () => {
                this.$router.push({ name: "profile" });
              },
            });
          } else if (validationKeys.includes("cart")) {
            this.showAlertModal({
              title: "Ошибка",
              message: err.extensions.validation["cart"][0],
            });
          }
          validationKeys.forEach((key) => {
            if (this.form[key]) {
              this.form[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
  },
  metaInfo: {
    title: "Оформление заказа",
  },
};
</script>

<style lang="stylus">
.order-page {
  padding-top 50px

  &__content {
    display flex
    justify-content space-between
    gap 24px

    +below(1024px) {
       flex-direction column
    }
  }

  &__left {
    width 100%
    display flex
    flex-direction column
    gap 16px
  }

  &__right {
    width 100%
    max-width 312px

    +below(1024px) {
      max-width: 100%;
    }
  }
}
</style>
