<template>
  <div class="default-modal my-cards-modal">
    <div class="default-modal__header my-cards-modal__header">
      <h2>Выберите способ оплаты</h2>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="default-modal__body my-cards-modal__body">
      <div v-if="cards && cards.length" class="my-cards-modal__items">
        <label v-for="(item, i) in cards" :key="i" class="my-cards-modal__item">
          <RadioComponent
            :checked="JSON.stringify(active) === JSON.stringify(item)"
            @change="handleRadioSelect(item)"
          />
          <IconComponent :name="setCardIcon(item)" class="my-cards-modal__icon" />
          <div class="my-cards-modal__item-text">
            <span>{{ setCardIcon(item) }}</span>
            <span class="my-cards-modal__item-title">**{{ item.masked_pan.slice(-4) }}</span>
          </div>
        </label>
      </div>
      <label class="my-cards-modal__item">
        <RadioComponent :checked="active === 0" @change="active = 0" />
        <div class="my-cards-modal__item-text">
          <span class="my-cards-modal__item-title">Новой картой онлайн</span>
        </div>
      </label>
      <!--      <span v-else>Способы оплаты отсутствуют</span>-->
      <!--      <button @click="addCard" class="btn btn&#45;&#45;md btn&#45;&#45;main my-cards-modal__btn my-cards-modal__btn&#45;&#45;add">-->
      <!--        Добавить-->
      <!--      </button>-->
      <button @click="chooseCard" class="btn btn--md btn--main my-cards-modal__btn">Выбрать</button>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import RadioComponent from "components/inputs/RadioComponent.vue";
import CARD_CREATE from "@/graphql/mutations/purchase/ClientCardCreate.graphql";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "MyCardsModal",
  props: {
    callback: Function,
  },
  data() {
    return {
      active: undefined,
    };
  },
  computed: {
    cards() {
      return this.$store.state.auth.my_cards;
    },
    token() {
      return this.$store.state.auth_token;
    },
  },
  methods: {
    setCardIcon(card) {
      switch (card.masked_pan.charAt(0)) {
        case "2":
          return "MIR";
        case "4":
          return "VISA";
        case "5":
          return "Mastercard";
      }
    },
    handleRadioSelect(item) {
      this.active = item;
    },
    addCard() {
      this.$apollo.provider.clients.purchase
        .mutate({
          mutation: CARD_CREATE,
          context: {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          },
        })
        .then(({ data }) => {
          if (data.ClientCardCreate && data.ClientCardCreate.payment_link) {
            let link = document.createElement("a");
            link.href = data.ClientCardCreate.payment_link;
            link.click();
          }
        });
    },
    chooseCard() {
      if (this.active === 0) {
        this.addCard();
      } else {
        if (this.callback) {
          this.callback(this.active);
          this.$emit("close");
        }
      }
    },
  },
  components: {
    IconComponent,
    RadioComponent,
    RemixIconComponent,
  },
};
</script>

<style lang="stylus">
.my-cards-modal {
  width 100%
  max-width 648px
  border-radius 16px
  padding 32px

  &__header {
    padding 0 0 22px 0 !important
  }

  &__icon {
    width 36px
    margin-left: 8px
  }

  &__body {
    padding 0 !important
    display flex
    flex-direction column
    gap 8px
  }

  &__items {
    width 100%
    display flex
    flex-direction column
    gap 8px
  }

  &__item {
    width 100%
    border 1px solid var(--border-color-2)
    padding 16px
    border-radius 16px
    display flex
    cursor pointer
    align-items center
  }

  &__item-text {
    display flex
    flex-direction column
    margin-left 8px
  }

  &__item-title {
    font-size .875em
    line-height 140%
    color var(--body_muted)
  }

  &__item-address {
    font-size .875em
    line-height 140%
    color var(--body_muted)
  }

  &__btn {
    width 100%
    height 46px

    &--add {
      align-self flex-end
      width auto
    }
  }
}
</style>
