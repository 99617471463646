<template>
  <div class="default-modal add-order-modal">
    <div class="default-modal__header">
      <span class="add-order-modal__title">Выберите действующий заказ</span>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="default-modal__body add-order-modal__body">
      <span class="add-order-modal__subtitle">
        Если вы хотите дополнить заказ на
        {{ clientRecipeType === types.RECEIVE_PICKUP ? "Доставку" : "Самовывоз" }}, то измените тип вашего
        текущего заказа
      </span>
      <template v-if="loading">
        <LoadingIndicator title="Загрузка" />
      </template>
      <template v-else>
        <div v-if="orders.length" class="add-order-modal__orders">
          <OrderComponent
            v-for="order in orders"
            :key="order.id"
            :data="order"
            is-radio
            :selected-order-id="activeOrder"
            @check-order="changeActiveOrder"
            @open-order-modal="openOrderModal"
          />
        </div>
        <span v-else class="add-order-modal__subtitle">Подходящих заказов нет</span>
      </template>
      <button
        v-if="orders.length"
        @click="addToOrder"
        :disabled="!activeOrder || submitLoading"
        class="btn btn--main btn--md add-order-modal__btn"
      >
        <LoadingIndicator v-if="submitLoading" title="" />
        <span v-else>Добавить в этот заказ</span>
      </button>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import MY_ORDERS from "@/graphql/queries/purchase/my_orders_paginate.graphql";
import OrderComponent from "../../profile/components/OrderComponent.vue";
import LoadingIndicator from "components/Loadingindicator.vue";
import OrderModal from "../../profile/modals/OrderModal.vue";
import { confirmOrderRepeat } from "../../../utils";
import PRODUCTS_ADD_TO_ORDER from "@/graphql/mutations/purchase/ProductsAddToOrder.graphql";
import AlertModal from "components/modals/components/AlertModal.vue";

export default {
  name: "OrderAddModal",
  components: { LoadingIndicator, OrderComponent, RemixIconComponent },

  data() {
    return {
      loading: true,
      orders: [],
      activeOrder: null,
      submitLoading: false,
    };
  },

  created() {
    this.$apollo.provider.clients.purchase
      .query({
        query: MY_ORDERS,
        variables: {
          statuses: [this.statuses.ORDER_CREATED, this.statuses.ORDER_PREPARING],
          type_id: this.clientRecipeType,
        },
        fetchPolicy: "no-cache",
        context: {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        },
      })
      .then(({ data }) => {
        this.orders = data.my_orders;
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    confirmOrderRepeat,
    openOrderModal(id) {
      this.$store.state._modals.push({
        component: OrderModal,
        options: {
          id: id,
          orderRepeat: () => this.confirmOrderRepeat(id),
        },
      });
    },
    changeActiveOrder(id) {
      if (this.activeOrder === id) {
        this.activeOrder = null;
      } else this.activeOrder = id;
    },
    addToOrder() {
      this.submitLoading = true;
      this.$apollo.provider.clients.purchase
        .mutate({
          mutation: PRODUCTS_ADD_TO_ORDER,
          variables: {
            order_id: this.activeOrder,
            products: this.productsInCart,
          },
          context: {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          },
        })
        .then(async () => {
          await this.$router.push({ name: "home" });
          this.$store.state._modals.push({
            component: AlertModal,
            options: {
              title: "Успешно",
              message: "Ваши товары успешно добавлены в заказ",
            },
          });
          await this.$store.dispatch("CART_DELETE", {
            state: this.$store.state,
            apollo: this.$apollo.provider.clients.purchase,
          });
        })
        .catch(() => {
          this.$store.state._modals.push({
            component: AlertModal,
            options: {
              title: "Ошибка",
              message: "Произошла непредвиденная ошибка, попробуйте обновить страницу и повторить попытку",
            },
          });
          console.log("error");
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },

  computed: {
    token() {
      return this.$store.state.auth_token;
    },
    statuses() {
      return this.$store.state._statuses;
    },
    types() {
      return this.$store.state._types;
    },
    clientRecipeType() {
      return this.$store.state.auth.client_delivery_info?.type_id;
    },
    productsInCart() {
      return this.$store.state.auth.cart.map((item) => {
        return {
          product_id: item.product.id,
          quantity: item.quantity,
        };
      });
    },
  },
};
</script>

<style lang="stylus">
.add-order-modal {
  width 100%
  max-width 650px
  padding: 32px

  &__body {
    display flex
    flex-direction column
    gap 16px
  }

  &__title {
    color: var(--gray-gray-900, #292929);
    font-size: 1.5rem
    font-weight: 500;
    line-height: 36px; /* 150% */
  }

  &__subtitle {
    color: var(--theme-body, #292929);
    font-size: 1rem
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }

  &__orders {
    width 100%
    display flex
    flex-direction column
    gap 8px
    max-height 420px
    overflow auto

    & .order-card {
      flex-shrink 0
    }
  }

  &__btn {
    width: 100%;
    font-size: 0.875rem
    font-weight: 400;
    line-height: 14px; /* 100% */
  }
}
</style>
