<template>
  <div class="promocode">
    <InputComponent
      placeholder="Промокод"
      v-model="promocode.value"
      :errors="promocode.errors"
      :disabled="!!activePromoCode"
      @keydown="addPromoCode"
      :messages="activePromoCode ? ['Промокод применен'] : null"
    />
    <button v-if="activePromoCode" @click="removePromoCode" class="btn btn--circle btn--red promocode__btn">
      <RemixIconComponent category="system" name="delete-bin-line" />
    </button>
    <button v-else @click="addPromoCode" class="btn btn--circle btn--gray promocode__btn">
      <IconComponent name="check" />
    </button>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import PROMO_SEARCH from "@/graphql/queries/purchase/promo_search.graphql";

export default {
  name: "EnteringPromoCodeComponent",
  components: { RemixIconComponent, IconComponent, InputComponent },

  data() {
    return {
      promocode: {
        value: "",
        errors: [],
      },
    };
  },

  beforeDestroy() {
    this.removePromoCode();
  },

  methods: {
    addPromoCode() {
      this.$apollo.provider.clients.purchase
        .query({
          query: PROMO_SEARCH,
          fetchPolicy: "no-cache",
          variables: {
            promo: this.promocode.value,
          },
          context: {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          },
        })
        .then(({ data }) => {
          this.$store.state.order_page.use_points = null;
          this.promocode.errors = [];
          this.percent = data.promo_search.percent;
          if (this.percent === null) {
            this.promocode.errors.push(data.promo_search.message);
          } else {
            this.$store.state.promo_products = data.promo_search.products;
            this.$store.state.activePromoCode = this.promocode.value;
            this.$store.state.order_page.promo_code_percent = data.promo_search.percent;

            // this.$store.state.order_page.promo_code_sale_value = this.calculatePromoCodeSalePriceValue(
            //   data.promo_search.percent
            // );
            //
            // for (let i = 0; i < this.cartItems.length + 1; i += 1) {
            //   if (data.promo_search.products.includes(this.cartItems[i]?.product?.id)) {
            //     this.cartItems[i].product.price.value =
            //       this.cartItems[i].product.price.value -
            //       this.cartItems[i].product.price.value * (this.percent / 100);
            //   }
            // }
            // /**
            //  Жесткий костыль надо фиксить (бек неудобный)
            //  */
            // let setsInCart = this.cartItems.filter((item) => item.product_set);
            // let productsInSets = setsInCart.flatMap((item) => item.set_items);
            // productsInSets.forEach((item) => {
            //   if (data.promo_search.products.includes(item.product.id)) {
            //     this.cartItems.forEach((cartItem, indexSet) => {
            //       let indexProductSet = cartItem.set_items?.findIndex(
            //         (p) => p.product.id === item.product.id
            //       );
            //       if (indexProductSet !== -1) {
            //         this.cartItems[indexSet].set_items[indexProductSet].product.price.value =
            //           this.cartItems[indexSet].set_items[indexProductSet].product.price.value -
            //           this.cartItems[indexSet].set_items[indexProductSet].product.price.value *
            //             (this.percent / 100);
            //       }
            //     });
            //   }
            // });
          }
        });
    },

    removePromoCode() {
      this.$store.state.promo_products = [];
      this.$store.state.activePromoCode = null;
      this.$store.state.order_page.promo_code_sale_value = null;
      this.promocode.value = "";
      // this.$store.dispatch("GET_CART_ITEMS", {
      //   apollo: this.$apollo.provider.clients,
      //   state: this.$store.state,
      // });
    },
  },

  computed: {
    activePromoCode() {
      return this.$store.state.activePromoCode;
    },
    cartItems() {
      return this.$store.state.auth.cart;
    },
    token() {
      return this.$store.state.auth_token;
    },
  },

  // watch: {
  //   cartItems: {
  //     handler() {
  //       if (this.activePromoCode) {
  //         this.removePromoCode();
  //         console.log("cart");
  //       }
  //     },
  //     deep: true,
  //   },
  // },
};
</script>

<style lang="stylus">
.promocode {
  display flex
  align-items flex-start
  gap 8px
  margin-bottom 24px

  &__btn {
    border-radius 16px
    width 52px
    height 46px

    &--active {
      background var(--main)

      .icon svg path {
        fill var(--white)
      }
    }
  }
}
</style>
