<template>
  <div class="order-settings">
    <div class="order-settings__content">
      <div class="order-settings__header">
        <span class="order-settings__title">Настройки заказа</span>
        <span class="order-settings__subtitle">
          Здесь вы можете настроить правила сборки и доставки заказа
        </span>
      </div>
      <div class="order-settings__checkboxes">
        <div class="order-settings__checkbox">
          <CheckboxComponent />
          <span class="order-settings__checkbox-text">Позвонить мне, когда курьер доедет</span>
        </div>
        <div class="order-settings__checkbox">
          <CheckboxComponent />
          <span class="order-settings__checkbox-text">Оставить заказ у двери</span>
        </div>
      </div>
      <div class="order-settings__bottom">
        <span class="order-settings__bottom-title">
          Что делать, если товар оказался некачественным или закончился?
        </span>
        <div class="order-settings__checkboxes">
          <div class="order-settings__checkbox">
            <CheckboxComponent />
            <span class="order-settings__checkbox-text">Позвонить мне</span>
          </div>
          <div class="order-settings__checkbox">
            <CheckboxComponent />
            <span class="order-settings__checkbox-text">Подобрать замену, если не смогу ответить</span>
          </div>
          <div class="order-settings__checkbox">
            <CheckboxComponent />
            <span class="order-settings__checkbox-text">Связаться через whatsapp</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";

export default {
  name: "OrderSettingsComponent",
  components: { CheckboxComponent },
};
</script>

<style lang="stylus">
.order-settings {
  padding 16px
  border 1px solid var(--border-color-2)
  border-radius 16px
  width 100%

  &__content {
    display flex
    flex-direction column
    gap 24px
  }

  &__header {
    display flex
    flex-direction column
    gap 7px
  }

  &__title {
    font-size 1.25em
    font-weight 500
    line-height 120%
    color var(--gray-900)
  }

  &__subtitle {
    font-size .875em
    line-height 140%
    color var(--body_muted)
  }

  &__body {
    display flex
    flex-direction column
    gap 24px
  }

  &__checkboxes {
    display flex
    flex-direction column
    gap 24px
  }

  &__checkbox {
    display flex
    align-items center
    gap 4px
  }

  &__checkbox-text {
    font-size .875em
    line-height 140%
    color var(--gray-900)
  }

  &__bottom {
    display flex
    flex-direction column
    gap 16px
    padding 16px
    background var(--white-3)
  }
}
</style>
