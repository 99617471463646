<template>
  <div class="order-contacts">
    <div class="order-contacts__content">
      <span class="order-contacts__title">Контакты</span>
      <div class="order-contacts__inputs">
        <InputComponent
          v-model="form.phone.value"
          :errors="form.phone.errors"
          title="Номер телефона"
          mask="+7 (###) ###-##-##"
          placeholder="Номер телефона"
          is-phone
        />
        <InputComponent v-model.trim="form.email.value" title="Почта" placeholder="Почта" />
      </div>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import { parseGqlErrors, resetErrors } from "@/utils";

export default {
  name: "OrderContactsComponent",
  components: { InputComponent },
  data() {
    return {
      form: {
        phone: {
          value: null,
          errors: [],
        },
        email: {
          value: null,
          errors: [],
        },
      },
    };
  },
  watch: {
    form: {
      handler() {
        this.$emit("input", this.form);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.user && this.user.phone) this.form.phone.value = this.user.phone;
    if (this.user && this.user.email) this.form.email.value = this.user.email;
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    parseGqlErrors,
    resetErrors,
  },
};
</script>

<style lang="stylus">
.order-contacts {
  padding 16px
  border 1px solid var(--border-color-2)
  border-radius 16px
  width 100%

  &__content {
    display flex
    flex-direction column
    gap 17px
  }

  &__title {
    font-size 1.25em
    font-weight 500
    line-height 120%
    color var(--gray-900)
  }

  &__inputs {
    display flex
    gap 16px

    +below(580px) {
      flex-direction column
    }
  }
}
</style>
