<template>
  <div class="order-payment" :class="{ 'order-payment--error': error }">
    <div class="order-payment__content">
      <span class="order-payment__title">Способы оплаты</span>
      <div class="order-payment__right">
        <div v-if="card" class="order-payment__card">
          <IconComponent :name="setCardIcon(card)" class="order-payment__icon" />
          <span>**** {{ card.masked_pan.slice(-4) }}</span>
        </div>
        <button
          @click="showCardsModal"
          class="btn btn--sm order-payment__btn"
          :class="{ 'btn--outline': !card, 'btn--gray': card }"
        >
          {{ card ? "Изменить" : "Выбрать" }}
        </button>
      </div>
    </div>
    <span class="order-payment__error" v-if="error">{{ error }}</span>
  </div>
</template>

<script>
import MyCardsModal from "@/components/modals/components/MyCardsModal.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "OrderPaymentComponent",
  components: { IconComponent },
  props: {
    card: Object,
    error: String,
  },
  methods: {
    setCardIcon(card) {
      switch (card.masked_pan.charAt(0)) {
        case "2":
          return "mir";
        case "4":
          return "visa";
        case "5":
          return "mastercard";
      }
    },
    showCardsModal() {
      this.$store.state._modals.push({
        component: MyCardsModal,
        options: {
          callback: (card) => {
            this.$emit("chooseCard", card);
          },
        },
      });
    },
  },
};
</script>

<style lang="stylus">
.order-payment {
  padding 16px
  border 1px solid var(--border-color-2)
  border-radius 16px
  width 100%

  &--error {
    border-color var(--red)
  }

  &__error {
    color var(--red)
  }

  &__content {
    display flex
    align-items center
    justify-content space-between
  }

  &__title {
    font-size 1.25em
    font-weight 500
    line-height 120%
    color var(--gray-900)
  }

  &__right {
    display flex
    align-items center
    gap: 16px
  }

  &__card {
    font-size: 0.875em
    line-height: 140%;
    display flex
    align-items center
    gap 8px
  }

  &__btn {
    padding 10px 22px
    font-size .75em
    line-height 120%
  }

  &__icon {
    width: 36px;
  }
}
</style>
