<template>
  <div class="order-address">
    <div class="order-address__content">
      <!--      <OrderTabsComponent />-->
      <div class="order-address__location">
        <span class="order-address__location-title">
          <template v-if="client_delivery_info && client_delivery_info.type_id === 1">
            Адрес самовывоза
          </template>
          <template v-if="client_delivery_info && client_delivery_info.type_id === 2">
            Адрес доставки
          </template>
        </span>
        <span class="order-address__location-subtitle">
          <template
            v-if="client_delivery_info && client_delivery_info.type_id === 1 && client_delivery_info.shop"
          >
            {{ client_delivery_info.shop.address }}
          </template>
          <template
            v-if="
              client_delivery_info &&
              client_delivery_info.type_id === 2 &&
              client_delivery_info.selected_address
            "
          >
            {{ client_delivery_info.selected_address.address }}
          </template>
        </span>
      </div>
      <div v-if="client_delivery_info && client_delivery_info.type_id === 2" class="order-address__inputs">
        <InputComponent
          v-model="form.flat.value"
          :errors="form.flat.errors"
          title="Квартира, офис"
          placeholder="Квартира, офис"
        />
        <InputComponent
          v-model="form.entrance.value"
          :errors="form.entrance.errors"
          title="Подъезд"
          placeholder="Подъезд"
        />
        <InputComponent
          v-model="form.floor.value"
          :errors="form.floor.errors"
          title="Этаж"
          placeholder="Этаж"
        />
        <InputComponent
          v-model="form.intercom.value"
          :errors="form.intercom.errors"
          title="Домофон"
          placeholder="Домофон"
        />
      </div>
      <div class="order-address__comment">
        <TextareaComponent
          v-model="form.client_comment.value"
          title="Комментарий"
          placeholder="Комментарий"
          width="100"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import TextareaComponent from "components/TextareaComponent.vue";
import OrderTabsComponent from "./OrderTabsComponent.vue";

export default {
  name: "OrderAddressComponent",
  components: { OrderTabsComponent, TextareaComponent, InputComponent },
  data() {
    return {
      form: {
        entrance: {
          value: null,
          errors: [],
        },
        intercom: {
          value: null,
          errors: [],
        },
        floor: {
          value: null,
          errors: [],
        },
        flat: {
          value: null,
          errors: [],
        },
        client_comment: {
          value: null,
          errors: [],
        },
      },
    };
  },
  watch: {
    form: {
      handler() {
        this.$emit("input", this.form);
      },
      deep: true,
    },
  },
  computed: {
    client_delivery_info() {
      let client_delivery_info = this.$store.state.auth.client_delivery_info;
      if (client_delivery_info && client_delivery_info.selected_address) {
        Object.keys(this.form).forEach((key) => {
          this.form[key].value = client_delivery_info.selected_address[key];
        });
      }
      return client_delivery_info;
    },
  },
};
</script>

<style lang="stylus">
.order-address {
  padding 16px
  border 1px solid var(--border-color-2)
  border-radius 16px
  width 100%

  &__content {
    display flex
    flex-direction column
    gap 16px
  }

  &__location {
    display flex
    flex-direction column
    padding 0 0 16px
    border-bottom 1px solid var(--border-color-2)
  }

  &__location-title {
    font-size .875em
    line-height 140%
    color(--body_muted)
  }

  &__location-subtitle {
    font-size 1.25em
    line-height 120%
    color var(--gray-900)
  }

  &__inputs {
    display flex
    gap 16px

    +below(790px) {
      flex-wrap wrap
    }
  }

  &__comment {
    label {
      width 100%
    }
  }

}
</style>
