<template>
  <div class="order-add">
    <div class="order-add__content">
      <span class="order-add__title">Добавить в действующий заказ?</span>
      <button @click="openOrderAddModal" class="btn btn--sm btn--outline order-add__btn">Выбрать заказ</button>
    </div>
  </div>
</template>

<script>

import OrderAddModal from "../modals/OrderAddModal.vue";
export default {
  name: "OrderAddComponent",

  methods: {
    openOrderAddModal() {
      this.$store.state._modals.push({
        component: OrderAddModal,
      })
    }
  },
};
</script>

<style lang="stylus">
.order-add {
  padding 16px
  border 1px solid var(--border-color-2)
  border-radius 16px
  width 100%

  &__content {
    display flex
    align-items center
    justify-content space-between
  }

  &__title {
    font-size 1.25em
    font-weight 500
    line-height 120%
    color var(--gray-900)
  }

  &__btn {
    padding 10px 22px
    font-size .75em
    line-height 120%
  }
}
</style>
